export const calculateDistance = ({ lat, lng }, currentPosition) => {
  if (!currentPosition) return

  if (!lat || !lng) return

  const currentLat = currentPosition.lat
  const currentLng = currentPosition.lng

  const R = 6371 // Radius of the Earth in kilometers
  const dLat = ((currentLat - lat) * Math.PI) / 180 // Convert Lat degrees to radians
  const dLon = ((currentLng - lng) * Math.PI) / 180 // Convert Lng degrees to radians
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((lat * Math.PI) / 180) *
      Math.cos((currentLat * Math.PI) / 180) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const distance = R * c // Distance in kilometers
  const roundedDistance = Math.round(distance * 10) / 10

  return roundedDistance
}
