import { loader } from "./map"

import IconMarker from "@svg/icon-marker.svg?raw"
import IconMarkerActive from "@svg/icon-marker-active.svg?raw"
import IconCurrentLocation from "@svg/icon-current-location.svg?raw"

import {
  DEFAULT_ZOOM,
  DEFAULT_POSITION,
  DEFAULT_SEARCH_RADIUS,
  DEFAULT_BOUNDS,
  STYLE,
} from "./constants"

import { calculateDistance } from "./utils"

const generateMarker = (icon, isCurrentLocation = false) => {
  return {
    url: "data:image/svg+xml;charset=utf-8," + encodeURIComponent(icon),
    size: new google.maps.Size(38, 38),
    scaledSize: new google.maps.Size(38, 38),
    origin: new google.maps.Point(0, 0),
    anchor: new google.maps.Point(19, isCurrentLocation ? 19 : 38),
  }
}

export default (allProviders, prefersReducedMotion = false) => ({
  mapOpen: true,
  map: null,
  infoWindow: null,
  prefersReducedMotion,
  isDesktop: window.innerWidth >= 1024,
  activeId: null,
  providers: JSON.parse(allProviders),
  providersWithLocations: null,
  currentPosition: null,
  isLoading: false,
  filters: {
    searchRadius: DEFAULT_SEARCH_RADIUS,
    address: null,
    acceptingNewPatients: "",
    language: "",
    providerAttributes: "",
  },
  previousSearchRadius: DEFAULT_SEARCH_RADIUS,
  bounds: DEFAULT_BOUNDS,
  filtersAreSet: false,
  clearUrlParams() {
    if (window.location.search) {
      const url = window.location.origin + window.location.pathname

      window.history.replaceState({}, document.title, url)
    }
  },
  resetFilters() {
    this.filters = {
      searchRadius: DEFAULT_SEARCH_RADIUS,
      address: null,
      acceptingNewPatients: "",
      language: "",
      providerAttributes: "",
    }

    this.currentPosition = DEFAULT_POSITION
    this.clearUrlParams()
  },
  checkSearchRadius(item) {
    return item.distance <= this.filters.searchRadius
  },
  checkLanguage(item) {
    if (this.filters.language !== "") {
      if (!item.providerLanguages) return false
      return item.providerLanguages.some(
        item => item.id.toString() === this.filters.language,
      )
    }
    return true
  },
  checkAcceptingNewPatients(item) {
    if (this.filters.acceptingNewPatients !== "") {
      return (
        item.acceptingNewPatients.value === this.filters.acceptingNewPatients
      )
    }
    return true
  },
  checkAttributes(item) {
    if (this.filters.providerAttributes !== "") {
      if (!item.providerAttributes) return false
      return item.providerAttributes.some(
        item => item.id.toString() === this.filters.providerAttributes,
      )
    }
    return true
  },
  getUserLocation() {
    this.isLoading = true
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          this.currentPosition = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          }
          this.isLoading = false
        },
        () => {
          // User location not found
          this.isLoading = false
          this.currentPosition = DEFAULT_POSITION
        },
      )
    } else {
      this.isLoading = false
      this.currentPosition = DEFAULT_POSITION
    }
  },
  get filteredProviders() {
    this.providers.forEach(provider => {
      provider.distance = calculateDistance(
        provider.coordinates,
        this.currentPosition,
      )
    })

    this.providers.sort((a, b) => {
      // If one has a distance and the other doesn't, sort by distance
      if (a.distance === undefined && b.distance !== undefined) {
        return 1
      } else if (a.distance !== undefined && b.distance === undefined) {
        return -1
      }

      // If both have distances, sort by distance
      if (a.distance !== undefined && b.distance !== undefined) {
        return a.distance - b.distance
      }

      // If both don't have distances don't sort
      return 0
    })

    let newProviders = this.providers.filter(item => {
      return (
        this.checkLanguage(item) &&
        this.checkAcceptingNewPatients(item) &&
        this.checkAttributes(item) &&
        this.checkSearchRadius(item)
      )
    })

    this.providersWithLocations = newProviders.filter(
      provider => provider.address.text !== "",
    )

    return newProviders
  },
  setActiveMarker(markers, activeLocation) {
    markers.find(marker => {
      const isMarkerActive =
        marker.position.lat() === activeLocation.lat &&
        marker.position.lng() === activeLocation.lng
      marker.setIcon(
        generateMarker(isMarkerActive ? IconMarkerActive : IconMarker),
      )
    })
  },
  updateMapBounds(markers) {
    const searchRadius = this.filters.searchRadius
    const bounds = this.map.getBounds()
    let markersVisibleInBounds = 0

    if (this.previousSearchRadius !== searchRadius) {
      this.previousSearchRadius = searchRadius

      this.map.panTo(this.currentPosition)

      const bounds = new google.maps.LatLngBounds()

      const latAdjustment = searchRadius / 110.574
      const lngAdjustment =
        searchRadius /
        (111.32 * Math.cos(this.currentPosition.lat * (Math.PI / 180)))

      const northEast = new google.maps.LatLng(
        this.currentPosition.lat + latAdjustment,
        this.currentPosition.lng + lngAdjustment,
      )

      const southWest = new google.maps.LatLng(
        this.currentPosition.lat - latAdjustment,
        this.currentPosition.lng - lngAdjustment,
      )

      bounds.extend(northEast)
      bounds.extend(southWest)
      this.map.fitBounds(bounds)
      markers.filter(marker => {
        if (bounds.contains(marker.getPosition())) {
          markersVisibleInBounds++
        }
      })
    }

    if (this.filteredProviders.length === 0) {
      this.map.panTo(this.currentPosition)
      // If no providers are found, do not update bounds
      return
    }

    if (markersVisibleInBounds === 0) {
      // Update bounds to show nearest 4 providers
      const nearestProviders = this.filteredProviders.slice(0, 4)
      const bounds = new google.maps.LatLngBounds()
      nearestProviders.forEach(provider => {
        bounds.extend(
          new google.maps.LatLng(
            provider.coordinates.lat,
            provider.coordinates.lng,
          ),
        )
      })
      bounds.extend(this.currentPosition)
      this.map.fitBounds(bounds)
    }

    this.map.panTo(this.currentPosition)
  },
  checkIfFiltersAreSet() {
    if (
      this.filters.language ||
      this.filters.acceptingNewPatients ||
      this.filters.providerAttributes ||
      this.filters.address ||
      this.filters.searchRadius !== DEFAULT_SEARCH_RADIUS
    ) {
      this.filtersAreSet = true
      return
    }
    this.filtersAreSet = false
  },
  getInitialFiltersFromParams() {
    const urlParams = new URLSearchParams(window.location.search)

    if (urlParams.get("language")) {
      this.filters.language = urlParams.get("language")
    }
    if (urlParams.get("acceptingNewPatients")) {
      this.filters.acceptingNewPatients = urlParams.get("acceptingNewPatients")
    }
    if (urlParams.get("address")) {
      this.filters.address = urlParams.get("address")
    }

    if (urlParams.get("lat") && urlParams.get("lng")) {
      this.currentPosition = {
        lat: Number(urlParams.get("lat")),
        lng: Number(urlParams.get("lng")),
      }
    }

    this.checkIfFiltersAreSet()
  },
  async init() {
    this.getInitialFiltersFromParams()

    if (!this.filters.address) {
      this.getUserLocation()
    }

    const importMaps = loader.importLibrary("maps")
    const importPlaces = loader.importLibrary("places")

    Promise.all([importMaps, importPlaces]).then(
      () => {
        const { Map } = google.maps
        const { Autocomplete } = google.maps.places

        const mapOptions = {
          center: this.currentPosition ?? DEFAULT_POSITION,
          zoom: DEFAULT_ZOOM,
          styles: STYLE,
          restriction: {
            latLngBounds: DEFAULT_BOUNDS,
            strictBounds: false,
          },
        }

        let markers = []

        this.map = new Map(document.getElementById("map"), mapOptions)

        // Add current location icon
        const currentLocationMarker = new google.maps.Marker({
          position: this.currentPosition,
          map: this.map,
          icon: generateMarker(IconCurrentLocation, true),
          title: "Current Location",
          zIndex: 999,
        })

        // Set up address input autocomplete
        const addressInput = document.getElementById("address")
        const autocomplete = new Autocomplete(addressInput, {
          bounds: DEFAULT_BOUNDS,
          strictBounds: true,
          componentRestrictions: { country: "nz" },
        })

        autocomplete.addListener("place_changed", () => {
          const place = autocomplete.getPlace()
          if (!place.geometry) {
            return
          }

          this.currentPosition = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          }
        })

        const addMarkers = () => {
          const newMarkers = []

          this.providersWithLocations.forEach(
            ({ coordinates, title, address, phone, website }) => {
              const { lat, lng } = coordinates

              if (!lat || !lng) return

              const latitude = Number(lat)
              const longitude = Number(lng)

              // Check if there is an existing marker
              const existingMarker = markers.find(
                marker =>
                  marker.getPosition().lat() === latitude &&
                  marker.getPosition().lng() === longitude,
              )

              if (existingMarker) {
                // If it exists add it to newMarkers array and remove it from the existing markers array
                newMarkers.push(existingMarker)
                markers = markers.filter(marker => marker !== existingMarker)
              } else {
                // If it doesn't exist, create a new marker and add it to the newMarkers array
                const marker = new google.maps.Marker({
                  position: { lat: latitude, lng: longitude },
                  map: this.map,
                  icon: generateMarker(IconMarker),
                  title: title,
                })

                marker.addListener("click", () => {
                  const provider = this.providers.find(
                    provider =>
                      provider.coordinates.lat === lat &&
                      provider.coordinates.lng === lng,
                  )

                  if (provider) {
                    this.activeId = provider.id
                  }

                  if (this.isDesktop) {
                    // Scroll provider card into view on pin click
                    const card = document.getElementById(
                      `provider-${provider.id}`,
                    )
                    if (card) {
                      card.scrollIntoView({
                        behavior: this.prefersReducedMotion ? "auto" : "smooth",
                        block: "nearest",
                        inline: "start",
                      })
                    }
                  } else {
                    // Open info window on mobile
                    if (this.infoWindow) {
                      this.infoWindow.close()
                    }

                    const renderPhoneNumber = () => {
                      if (!phone.link || !phone.text) return ""
                      return `<div><a class="link font-normal" href="${phone.link}">${phone.text}</a></div>`
                    }

                    const renderWebsite = () => {
                      if (!website) return ""
                      return `<div><a class="!font-normal link-external" href="${website}" target="_blank"><span>Visit Website</span></a></div>`
                    }

                    this.infoWindow = new google.maps.InfoWindow({
                      content: `<div class='text-sm w-48 space-y-2 pb-1'>
                        <h4 class='font-medium'>${title}</h4>
                        <div>${address}</div>
                        ${renderPhoneNumber()}
                        ${renderWebsite()}
                      </div>`,
                    })
                    this.infoWindow.open({
                      anchor: marker,
                      map: this.map,
                      shouldFocus: true,
                    })
                    google.maps.event.addListener(this.map, "click", () =>
                      this.infoWindow.close(),
                    )
                  }
                })

                newMarkers.push(marker)
              }
            },
          )

          // Remove markers that are no longer in the updated array
          markers.forEach(marker => {
            marker.setMap(null)
          })

          // Update markers array
          markers = newMarkers
        }

        addMarkers()

        // Add event listener to check if map is fully loaded and then update bounds if filters are set
        if (this.filtersAreSet) {
          google.maps.event.addListenerOnce(this.map, "tilesloaded", () => {
            this.updateMapBounds(markers)
          })
        }

        this.$watch(
          () => this.currentPosition,
          () => {
            // Current Position has changed so update center
            this.map.panTo(this.currentPosition)
            currentLocationMarker.setPosition(this.currentPosition)

            // Then update distance for each provider
            this.providersWithLocations.forEach(provider => {
              provider.distance = calculateDistance(
                provider.coordinates,
                this.currentPosition,
              )
            })
          },
        )

        this.$watch(
          () => this.filteredProviders,
          () => {
            // When filters change, update providers with locations array and reset markers
            this.providersWithLocations = this.filteredProviders.filter(
              provider =>
                provider.coordinates.lat !== null ||
                provider.coordinates.lng !== null,
            )
            this.activeId = null
            addMarkers()
            this.updateMapBounds(markers)
            this.clearUrlParams()
            this.checkIfFiltersAreSet()
          },
        )

        this.$watch(
          () => this.activeId,
          () => {
            // Handle clicking on provider card or pin to set active marker
            if (this.activeId !== null) {
              const activeProvider = this.providers.find(
                provider => provider.id === this.activeId,
              )
              if (
                activeProvider &&
                (activeProvider.coordinates.lat !== null ||
                  activeProvider.coordinates.lng !== null)
              ) {
                const { lat, lng } = activeProvider.coordinates
                const activeCoordinates = { lat: Number(lat), lng: Number(lng) }
                this.map.setZoom(DEFAULT_ZOOM)
                this.map.panTo(activeCoordinates)

                this.setActiveMarker(markers, activeCoordinates)
              } else {
                // If active provider doesn't have a location, reset all markers and pan to center
                markers.forEach(marker =>
                  marker.setIcon(generateMarker(IconMarker)),
                )
                this.map.panTo(this.currentPosition)
              }
            }
          },
        )
      },
      () => console.error("Google Maps failed to load"),
    )
  },
})
