import { loader } from "./map"

import { DEFAULT_BOUNDS } from "./constants"

export default () => ({
  currentPosition: null,
  filters: {
    address: "",
    provider: "",
    language: "",
    acceptingNewPatients: "",
  },
  handleSubmit(event) {
    let queryString = Object.keys(this.filters)
      .map(key => (this.filters[key] ? `${key}=${this.filters[key]}` : ""))
      .filter(Boolean)
      .join("&")

    if (this.currentPosition) {
      queryString += `&lat=${this.currentPosition.lat}&lng=${this.currentPosition.lng}`
    }

    const redirectUrl = queryString
      ? `/health-directory?${queryString}`
      : "/health-directory"
    window.location.href = redirectUrl
  },
  async init() {
    const { Autocomplete } = await loader.importLibrary("places")
    // Set up address input autocomplete
    const addressInput = document.getElementById("address")
    const autocomplete = new Autocomplete(addressInput, {
      bounds: DEFAULT_BOUNDS,
      strictBounds: true,
      componentRestrictions: { country: "nz" },
    })
    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace()
      if (!place.geometry) {
        return
      }
      this.filters.address = place.formatted_address
      this.currentPosition = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      }
    })
  },
})
