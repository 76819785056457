import "../css/app.css"
import Alpine from "alpinejs"
import * as focusTrap from "focus-trap"
import healthDirectory from "./health-directory"
import healthDirectorySearch from "./health-directory-search"
import imageAndTextSlider from "./image-and-text-slider"
import tableOfContents from "./table-of-contents"

window.Alpine = Alpine
window.focusTrap = focusTrap

// @ts-ignore
Alpine.data("healthDirectory", healthDirectory)
Alpine.data("healthDirectorySearch", healthDirectorySearch)
Alpine.data("imageAndTextSlider", imageAndTextSlider)
Alpine.data("tableOfContents", tableOfContents)

Alpine.start()
