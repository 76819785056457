import Swiper from "swiper"
import {
  EffectFade,
  Navigation,
  Pagination,
  Autoplay,
  Keyboard,
} from "swiper/modules"

import "swiper/css"
import "swiper/css/effect-fade"
import "swiper/css/navigation"
import "swiper/css/pagination"

Swiper.use([EffectFade, Navigation, Pagination, Autoplay, Keyboard])

export default (prefersReducedMotion = false) => ({
  swiper: null,
  prefersReducedMotion,
  activeIndex: 0,
  init() {
    this.$nextTick(() => {
      this.swiper = new Swiper(".swiper", {
        loop: true,
        effect: "fade",
        fadeEffect: {
          crossFade: true,
        },
        speed: 1000,
        slidesPerView: 1,
        spaceBetween: 16,
        autoplay: !prefersReducedMotion
          ? {
              delay: 5000,
              disableOnInteraction: true,
            }
          : false,
        navigation: {
          nextEl: ".swiper-btn-next",
          prevEl: ".swiper-btn-prev",
        },
        keyboard: {
          enabled: true,
          onlyInViewport: true,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        modules: [EffectFade, Navigation, Pagination, Autoplay, Keyboard],
      })

      this.swiper.on("slideChange", () => {
        this.activeIndex = this.swiper.realIndex
      })
    })
  },
})
