import tocbot from "tocbot"

export default fieldId => ({
  open: false,
  init() {
    const options = {
      tocSelector: fieldId
        ? `.js-table-of-contents-${fieldId}`
        : ".js-table-of-contents",
      contentSelector: fieldId ? `.js-body-${fieldId}` : ".js-body",
      headingSelector: "h2, h3",
      scrollSmooth: false,
      hasInnerContainers: true,
    }
    this.$nextTick(() => {
      tocbot.init(options)
    })
  },
})
